@import url(https://fonts.googleapis.com/css?family=Jura&display=swap);
.close-animation{
  -webkit-animation-name: close;
          animation-name: close;
  -webkit-animation-duration: 400ms;
          animation-duration: 400ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.open-animation{
  -webkit-animation-name: open;
          animation-name: open;
  -webkit-animation-duration: 400ms;
          animation-duration: 400ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes close {
  from { }
  to   { 
    width: 0px;
    padding-left: 0px;
    padding-right: 0px; 
  }
}

@keyframes close {
  from { }
  to   { 
    width: 0px;
    padding-left: 0px;
    padding-right: 0px; 
  }
}

@-webkit-keyframes open {
  from { 
    width: 0px;
    padding-left: 0px;
    padding-right: 0px; 
  }
  to   { }
}

@keyframes open {
  from { 
    width: 0px;
    padding-left: 0px;
    padding-right: 0px; 
  }
  to   { }
}
.sponsor-list-container{
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
}

.sponsor-list-item{
  margin: 16px 32px;
  /* background-color: blueviolet; */
  display: flex;
  flex-direction: row;
  height: 60px;
  width:100%;
 cursor: pointer;
}


.sponsor-details{
   background-color:  rgba(55, 121, 243, 0.6);
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   width: 90%;
   height: 100%;
  font-family: Arial;
  opacity: 1;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0px;
  min-width: 0px;
  font-size: 19.2px;
  color: black;
  outline: none;
  overflow: hidden;
  align-items: center;
  margin-right: 12.8px;
}

.sponsor-details:hover {
  border: 3px solid  rgb(75, 15, 216);
}

.banner-image{
  width: 80px;
  height: 50px;
}

.sponsor-banner{
  width: 15%;
  overflow: hidden;
}
.sponsor-id, .sponsor-tier{
  width: 7.5%;
  overflow: hidden;
}

.sponsor-url{
  width: 40%;
  text-align: center;
  overflow: hidden;
}
.sponsor-name{
  width: 30%;
  padding-left: 5px;
  overflow: hidden;
}


.sponsor-edit-button{
  background-color: rgba(101, 21, 228, 0.6);
  height: 43px;
  width: 43px;
  font-family: Arial;
  opacity: 1;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0px;
  min-width: 0px;
  font-size: 16px;
  color: black;
  outline: none;
  overflow: hidden;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.sponsor-edit-button:hover {
  border: 3px solid rgb(75, 15, 216);
}

.edit-image{
  height: 90%;
  width:90%;
}
.sponsor-create-container{
  display:flex;
  flex-direction:column;
  padding-top: 20px;
}

.sponsor-create-form{
  display:flex;
  flex-direction:column;
  width: 35%;
  justify-content: center;
  margin-left: 32.5%;
  margin-right: 32.5%;
}

.create-form-input{
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 24px;
  background-color: white;
  border: 1px solid black;
  padding-left:10px;
  border-radius: 0px;
}
.tier{
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:url('https://imageog.flaticon.com/icons/png/512/60/60995.png');
  background-position: 98% 55%;
  background-repeat: no-repeat;
  background-size: 3%;
}

.create-form-input-label{
  font-size: 24px;
}

.submit-button{
  font-family: Arial;
  background-color: rgb(75, 15, 216);
  opacity: 1;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0px;
  min-width: 0px;
  width: 100%;
  height: 38px;
  font-size: 24px;
  color: white;
  outline: none;
  overflow: hidden;
}

.button-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 150px;
  padding-left:50px;
}

.delete-button{
  font-family: Arial;
  /* background-image:url("https://static.thenounproject.com/png/3058-200.png");
  background-repeat: no-repeat;
  background-size:35%;
  background-position: 50% 50%; */
  font-size: 25px;
  text-align: center;
  line-height: -3;
  background-color: rgb(55, 121, 243);
  opacity: 1;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0px;
  min-width: 0px;
  width: 55px;
  height: 40px;
  color: black;
  outline: none;
  overflow: hidden;
  /* position: relative;
  left:85%; */
  cursor: pointer;
}

.arrow{
  width:200px;
  font-size: 30px;
  line-height: 1.4;
}
.banner-image-create{
  width:320px;
  height: 50px;
}

.banner-preview{
  height: 50px;
  width: 320px;
}
html{
    height: 100%;
}

body > #root > div {
    min-height: 100vh;
}

body{
    color:#4c4c4c;
    margin:0px;
}

.fade-in-animation{
    -webkit-animation: fadein 250ms;
            animation: fadein 250ms;
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.my-node-enter {
    opacity: 0;
}
.my-node-enter-active {
    opacity: 1;
    -webkit-transition: opacity 200ms;
    transition: opacity 200ms;
}
.my-node-exit {
    opacity: 1;
}
.my-node-exit-active {
    opacity: 0;
    -webkit-transition: opacity 200ms;
    transition: opacity 200ms;
}

/* Import Google Fonts */
