.sponsor-create-container{
  display:flex;
  flex-direction:column;
  padding-top: 20px;
}

.sponsor-create-form{
  display:flex;
  flex-direction:column;
  width: 35%;
  justify-content: center;
  margin-left: 32.5%;
  margin-right: 32.5%;
}

.create-form-input{
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 24px;
  background-color: white;
  border: 1px solid black;
  padding-left:10px;
  border-radius: 0px;
}
.tier{
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:url('https://imageog.flaticon.com/icons/png/512/60/60995.png');
  background-position: 98% 55%;
  background-repeat: no-repeat;
  background-size: 3%;
}

.create-form-input-label{
  font-size: 24px;
}

.submit-button{
  font-family: Arial;
  background-color: rgb(75, 15, 216);
  opacity: 1;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0px;
  min-width: 0px;
  width: 100%;
  height: 38px;
  font-size: 24px;
  color: white;
  outline: none;
  overflow: hidden;
}

.button-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 150px;
  padding-left:50px;
}

.delete-button{
  font-family: Arial;
  /* background-image:url("https://static.thenounproject.com/png/3058-200.png");
  background-repeat: no-repeat;
  background-size:35%;
  background-position: 50% 50%; */
  font-size: 25px;
  text-align: center;
  line-height: -3;
  background-color: rgb(55, 121, 243);
  opacity: 1;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0px;
  min-width: 0px;
  width: 55px;
  height: 40px;
  color: black;
  outline: none;
  overflow: hidden;
  /* position: relative;
  left:85%; */
  cursor: pointer;
}

.arrow{
  width:200px;
  font-size: 30px;
  line-height: 1.4;
}
.banner-image-create{
  width:320px;
  height: 50px;
}

.banner-preview{
  height: 50px;
  width: 320px;
}