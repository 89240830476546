.sponsor-list-container{
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
}

.sponsor-list-item{
  margin: 16px 32px;
  /* background-color: blueviolet; */
  display: flex;
  flex-direction: row;
  height: 60px;
  width:100%;
 cursor: pointer;
}


.sponsor-details{
   background-color:  rgba(55, 121, 243, 0.6);
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   width: 90%;
   height: 100%;
  font-family: Arial;
  opacity: 1;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0px;
  min-width: 0px;
  font-size: 19.2px;
  color: black;
  outline: none;
  overflow: hidden;
  align-items: center;
  margin-right: 12.8px;
}

.sponsor-details:hover {
  border: 3px solid  rgb(75, 15, 216);
}

.banner-image{
  width: 80px;
  height: 50px;
}

.sponsor-banner{
  width: 15%;
  overflow: hidden;
}
.sponsor-id, .sponsor-tier{
  width: 7.5%;
  overflow: hidden;
}

.sponsor-url{
  width: 40%;
  text-align: center;
  overflow: hidden;
}
.sponsor-name{
  width: 30%;
  padding-left: 5px;
  overflow: hidden;
}


.sponsor-edit-button{
  background-color: rgba(101, 21, 228, 0.6);
  height: 43px;
  width: 43px;
  font-family: Arial;
  opacity: 1;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0px;
  min-width: 0px;
  font-size: 16px;
  color: black;
  outline: none;
  overflow: hidden;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.sponsor-edit-button:hover {
  border: 3px solid rgb(75, 15, 216);
}

.edit-image{
  height: 90%;
  width:90%;
}